import { templeFunctionArgsParser } from '../utils'

// this will be determined during the build time
let RELEASE_SCOPE, ENVIRONMENT
const REACT_APP_CDN_URL = process.env.REACT_APP_CDN_URL || process.env.STORYBOOK_APP_CDN_URL
const RECAPTCHA_KEY = process.env.REACT_APP_GRECAPTCHA_KEY
const GENERIC_NETWORK_ERRORS = ['SERVER_ERROR']
const FRESHID_CLIENT_ID = process.env.REACT_APP_FRESHID_CLIENT_ID
const FRESHCHAT_BILLING_SUPPORT_TOKEN = process.env.REACT_APP_FRESHCHAT_BILLING_SUPPORT_TOKEN
const UNIFIED_URL_DOMAIN = process.env.REACT_APP_UNIFIED_URL_DOMAIN
const REACT_APP_SENTRY_DNS = process.env.REACT_APP_SENTRY_DNS
const REACT_APP_HEAP_ID = process.env.REACT_APP_HEAP_ID
const REACT_APP_SENTRY_RELEASE = process.env.REACT_APP_SENTRY_RELEASE
const ENABLE_UBX_AUDIT_LOGS = process.env.REACT_APP_ENABLE_UBX_AUDIT_LOGS || 'true'
const ENABLE_SESSION_MANAGEMENT_FEATURE =
  process.env.REACT_APP_ENABLE_SESSION_MANAGEMENT_FEATURE || 'true'
const ENABLE_CUSTOM_DOMAIN = process.env.REACT_APP_ENABLE_CUSTOM_DOMAIN || 'false'

const APP_VERSION = process.env.REACT_APP_VERSION || new Date().getTime()

const BUILD = {
  STABLE: 'stable',
  ALPHA: 'alpha',
  LATEST: 'latest',
  BETA: 'beta'
}
try {
  // this will be replaced by create-react-app during  build
  // https://facebook.github.io/create-react-app/docs/adding-custom-environment-variables
  RELEASE_SCOPE = process.env.REACT_APP_RELEASE_SCOPE
} catch (e) {
  console.error('Could not find the release scope for the build. Falling back to default')
  RELEASE_SCOPE = BUILD.STABLE
}

switch (RELEASE_SCOPE) {
  case BUILD.LATEST:
  case BUILD.ALPHA:
    ENVIRONMENT = 'qa'
    break
  case BUILD.BETA:
    ENVIRONMENT = 'staging'
    break
  case BUILD.STABLE:
    ENVIRONMENT = 'production'
    break
  default:
    ENVIRONMENT = 'production'
}

// Template function to return the image prepended with CDN URL and assets/images location
function IMAGE_CDN_URL(...args) {
  const parsedArgs = templeFunctionArgsParser(args)
  return `${REACT_APP_CDN_URL}assets/images/${parsedArgs.join('')}`
}

export {
  BUILD,
  ENVIRONMENT,
  RELEASE_SCOPE,
  IMAGE_CDN_URL,
  REACT_APP_CDN_URL,
  GENERIC_NETWORK_ERRORS,
  RECAPTCHA_KEY,
  FRESHID_CLIENT_ID,
  FRESHCHAT_BILLING_SUPPORT_TOKEN,
  UNIFIED_URL_DOMAIN,
  REACT_APP_HEAP_ID,
  REACT_APP_SENTRY_DNS,
  REACT_APP_SENTRY_RELEASE,
  APP_VERSION,
  ENABLE_UBX_AUDIT_LOGS,
  ENABLE_CUSTOM_DOMAIN,
  ENABLE_SESSION_MANAGEMENT_FEATURE
}
// local storage key for storing language option
export const DEFAULT_LANGUAGE = 'en-US'
export const TRANSLATION_PATH = 'assets/translations'
